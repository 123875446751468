import React, { useEffect, useLayoutEffect, useState } from "react";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import * as user from "./user.scss";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { RiArrowLeftLine, RiArrowDownSLine } from "react-icons/ri";
import { FormGroup, FormLabel, Select } from "@mui/material";
import * as css from "../../../styles/settings.scss";
import { Checkbox, FormControlLabel, FormControl } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import * as styles from "./rsuiteTimePicker.scss";
import getTokenFromLocalStorage from "../../../utils/token";
import { useDispatch, useSelector } from "react-redux";
import { createAnnouncement } from "../../../store/slices/createAnnouncementsSlice";
import { successToast } from "../../../responses/successToast";
import { fetchUserList } from "../../../store/slices/listAllUser";
import { DatePicker } from "rsuite";
// Validation schema
const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
  system_id: Yup.array()
    .min(1, "Select Systems & Roles To Whom Announcement Display is required")
    .required("System ID is required"),
  display_till: Yup.string().when("display_till_time", {
    is: (display_till_time) => display_till_time !== null, // Check if display_till_time is set
    then: (schema) => schema.required("Until Date is required"), // If set, then display_till is required
    otherwise: (schema) => schema.notRequired(), // If not set, then display_till is not required, can also be null
  }),
  // The array itself must be present
  // roles_id: Yup.array()
  //   .of(
  //     Yup.number()
  //       .integer('Each role_id must be an integer')
  //       .required('Role ID is required')
  //   )
  //   .min(1, 'At least one role_id is required')
  //   .required('Roles ID is required'),
  // days_until:Yup.number().required("Author name is required")
});

const AnnouncementCreate = () => {
  const [checkedState, setCheckedState] = useState({});
  const [selectedAppRoles, setSelectedAppRoles] = useState({});
  const [adminRoles,setAdminRoles]=useState([])
  const [roleErrors, setRoleErrors] = useState({});
  let [appNames, setAppNames] = useState([]);
  const token = getTokenFromLocalStorage();
  const dispatch = useDispatch();
  const authorData = useSelector((state) => {
    return state.userList.userList?.data;
  });
  
  useEffect(() => {
    setSelectedAppRoles(() => {
      return appNames.reduce((acc, app) => {
        acc[app.app_name] = app.roles
          .filter((role) => role.role_name === "Admin")
          .map((role) => role.id); // Select Admin roles
        return acc;
      }, {});
    });
    const adminRoleIds = appNames
    .flatMap((app) => app.roles.filter((role) => role.role_name === "Admin"))
    .map((role) => role.id);
    setAdminRoles(adminRoleIds)
  }, [appNames]);
  // const formattedAuthorData = authorData?.map((item) => ({
  //   value: item.id,
  //   label: item.full_name,
  // }));

  const location = useLocation();
  let { state } = location;
  if (state == null) {
    state = {
      status: 0,
    };
  }

  useLayoutEffect(() => {
    try {
      dispatch(fetchUserList(token));
    } catch (error) {
      console.error("Loading Data Failed", error);
    }
  }, []);
  const formatYYYYMMDD = (dateToFormat) => {
    const year = dateToFormat.getFullYear();
    const month = String(dateToFormat.getMonth() + 1).padStart(2, "0");
    const day = String(dateToFormat.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };
  const formatTimeFromDayjs = (dayjsObject) => {
    if (dayjsObject?.isValid()) {
      // Check if the dayjs object is valid
      const formattedDate = dayjsObject.format("HH:mm:ss"); // Directly format the time
      return formattedDate;
    } else {
      return null;
    }
  };
  //  const formatDDMMYYYY = dateToFormat => {
  //     return moment(dateToFormat).format('DD-MM-YYYY');
  // };
  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      system_id: [],
      roles_id: [],
      display_till: null,
      display_till_time: null,
      is_priority: 0,
    },
    validationSchema: validationSchema,
    onSubmit: async (announcementData, { setSubmitting }) => {
      setSubmitting(true);

      // if (Object.values(roleErrors).includes(true)) {
      //   return;
      // }

      const dateParams = formik.values.display_till
        ? formatYYYYMMDD(formik.values.display_till)
        : "";
      const timeParams = formik.values.display_till_time
        ? formatTimeFromDayjs(dayjs(formik.values.display_till_time))
        : "";
      const userData = {
        title: announcementData.title,
        description: announcementData.description,

        system_id: announcementData.system_id.map((system) => parseInt(system)),
        roles_id: Object.values(announcementData.roles_id).flat(),

        display_till: formik.values.display_till
          ? `${dateParams} ${timeParams}`
          : null,
        is_priority: announcementData.is_priority,
        display_till_time: announcementData.display_till_time,
      };

      dispatch(createAnnouncement(userData, token))
        .then((response) => {
          if (response.success) {
            successToast(response.message);
            navigate("/announcements", {
              state: {
                status: state.status ? state.status : 0,
                filterApplied: state.filterApplied
                  ? state.filterApplied
                  : false,
                filterYear: state.filterYear ? +state.filterYear : "",
                filterMonth: state.filterMonth ? state.filterMonth : "",
                filterType: state.filterType ? state.filterType : "",
                filterPostedBy: state.filterPostedBy
                  ? state.filterPostedBy
                  : "",
                filterPostedDate:state.filterPostedDate?state.filterPostedDate:'',
                page:state.page?state.page:1,
                rowsPerPage:state.rowsPerPage?state.rowsPerPage:25
              },
            });
          } else {
            console.error("Error creating user:", response.error);
          }
        })
        .catch((error) => {
          console.error("Error creating user:", error.message);
        });
      setSubmitting(false);
    },
  });
  const getFieldProps = (field) => ({
    id: `outlined-basic-${field.name}`,
    fullWidth: true,
    variant: "outlined",
    size: "small",
    placeholder: field.placeholder || `Enter ${field.label}`,
    name: field.name,
    value: formik.values[field.name],
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
    // error: formik.touched[field.name] && Boolean(formik.errors[field.name]),
  });

  // Handle checkbox state
  // Handle checkbox state
  const handleCheckboxChange = (appName, event, appIndex) => {
    const isChecked = event.target.checked;
    setCheckedState((prevState) => ({
      ...prevState,
      [appName]: { isChecked },
    }));

    // If checkbox is unchecked, clear the selected roles and remove app_name from system_id
    if (!isChecked) {
      setSelectedAppRoles((prevState) => ({
        ...prevState,
        [appName]: prevState[appName]?.filter((val)=>adminRoles?.includes(val)),
      }));

      // Clear the formik field for that app's roles
      formik.setFieldValue("roles_id", {
        ...formik.values.roles_id,
        [appName]:[...selectedAppRoles[appName]], // Clear the role selection
      });

      // Remove app from system_id when unchecked
      formik.setFieldValue(
        "system_id",
        formik.values.system_id.filter((id) => id !== appIndex + 1) // Remove corresponding system ID
      );

      setRoleErrors((prevState) => ({
        ...prevState,
        [appName]: false,
      }));
    } else {
      // If checkbox is checked, add app_name to system_id
      formik.setFieldValue("system_id", [
        ...formik.values.system_id,
        appIndex + 1,
      ]);
      formik.setFieldValue("roles_id", {
        ...formik.values.roles_id,
        [appName]: [...selectedAppRoles[appName]], // Clear the role selection
      });
      setRoleErrors((prevState) => ({
        ...prevState,
        [appName]: true,
      }));
    }
  };

  useLayoutEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/applications?fields=id,app_name`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = response.data.data;

        setAppNames(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  // Handle role selection state
  const handleRoleChange = (appName, event) => {
    formik.setFieldValue("roles_id", {
      ...formik.values.roles_id,
      [event.target.name]: event.target.value, // Use bracket notation to set dynamic key
    });

    // formik.setFieldValue("roles_id", [
    //   ...new Set([...formik.values.roles_id, ...event.target.value])
    // ]);
    setSelectedAppRoles((prevState) => ({
      ...prevState,
      [appName]: event.target.value,
    }));

    // Remove error if a role is selected
    if (event.target.value) {
      setRoleErrors((prevState) => ({
        ...prevState,
        [appName]: false,
      }));
    }
    if (event.target.value?.length == 0) {
      setRoleErrors((prevState) => ({
        ...prevState,
        [appName]: true,
      }));
    }
  };

  const navigate = useNavigate();
  const styles = {
    label: {
      fontWeight: "bold",
      paddingTop: "8px",
      color: "#323232",
      fontSize: "13px",
      // marginTop:'8px !important'
    },
  };
  return (
    <>
      <Box className="content-header">
        <Typography variant="h2" component="h2" className="title-with-btn">
          <Button
            variant="outlined"
            className="action-btn back-btn"
            onClick={() =>
              navigate("/announcements", {
                state: {
                  status: state.status ? state.status : 0,
                  filterApplied: state.filterApplied
                    ? state.filterApplied
                    : false,
                  filterYear: state.filterYear ? +state.filterYear : "",
                  filterMonth: state.filterMonth ? state.filterMonth : "",
                  filterType: state.filterType ? state.filterType : "",
                  filterPostedBy: state.filterPostedBy
                    ? state.filterPostedBy
                    : "",
                  filterPostedDate:state.filterPostedDate?state.filterPostedDate:'',
                  page:state.page?state.page:1,
                  rowsPerPage:state.rowsPerPage?state.rowsPerPage:25
                },
              })
            }
            startIcon={<RiArrowLeftLine />}
            disableRipple
            // onClick handler
          ></Button>
          Post Announcement
        </Typography>
      </Box>

      <Box className="content-wrapper">
        <form onSubmit={formik.handleSubmit}>
          <Box className="card-wrapper">
            <Grid container rowSpacing={3} columnSpacing={3}>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <Typography
                  variant="h3"
                  component="h3"
                  sx={{ marginBottom: "16px" }}
                >
                  Announcement Detail
                </Typography>
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography variant="body1" component="label">
                      Title
                      <Typography
                        variant="body1"
                        component="span"
                        className="asterisk"
                      >
                        *
                      </Typography>
                    </Typography>
                    <TextField
                      className="input-field"
                      {...getFieldProps({
                        name: "title",
                        required: true,
                        placeholder: "Enter Announcement Title",
                      })}
                    />
                    {formik.touched.title && formik.errors.title && (
                      <FormHelperText className="error-message">
                        {formik.errors.title}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography variant="body1" component="label">
                      Description
                      <Typography
                        variant="body1"
                        component="span"
                        className="asterisk"
                      >
                        *
                      </Typography>
                    </Typography>
                    <Box
                      className={`announcement-ck-editor-wrapper ${
                        formik.touched.description &&
                        Boolean(formik.errors.description)
                          ? "has-error"
                          : ""
                      }`}
                      borderRadius={"8px !important"}
                      sx={{ borderColor: "red !important" }}
                    >
                      <CKEditor
                        name="description"
                        editor={ClassicEditor}
                        sx={{ borderColor: "red !important" }}
                        config={{
                          toolbar: [
                            "heading",
                            "bold",
                            "italic",
                            "link",
                            "blockquote",
                            "numberedList",
                            "bulletedList",
                            "indent",
                            "outdent",
                            "undo",
                            "redo",
                          ],
                          placeholder: "Enter Announcement Description",
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          formik.setFieldValue("description", data);
                        }}
                        onBlur={() =>
                          formik.setFieldTouched("description", true)
                        }
                      />
                    </Box>
                    {formik.touched.description &&
                      formik.errors.description && (
                        <FormHelperText className="error-message">
                          {formik.errors.description}
                        </FormHelperText>
                      )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item lg={6} md={12} sm={12} xs={12}>
                <Typography
                  variant="h3"
                  component="h3"
                  sx={{ marginBottom: "16px" }}
                >
                  Select Systems & Roles To Whom Announcement Display
                </Typography>
                <FormGroup>
                  {appNames.map((app, index) => (
                    <>
                      <Grid
                        container
                        mb={2}
                        columnSpacing={1}
                        rowSpacing={0}
                        direction="row"
                        alignItems="center"
                        key={app.app_name}
                      >
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          <FormGroup
                            name="system_id"
                            value={formik.values.system_id}
                            onChange={(e) => formik.handleChange(e)}
                          >
                            <FormControlLabel
                              value={Number(index + 1)}
                              control={
                                <Checkbox
                                  name="system_id"
                                  value={Number(index + 1)}
                                  // disableRipple
                                  checked={
                                    checkedState[app.app_name]?.isChecked ||
                                    false
                                  }
                                  onChange={(event) => {
                                    handleCheckboxChange(app.app_name, event);
                                  }}
                                />
                              }
                              label={app.app_name}
                              sx={{ marginRight: "0px !important" }}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={12} sm={8} md={8} lg={8}>
                          <FormControl fullWidth>
                            <Select
                              id={app.app_name}
                              name={app.app_name}
                              value={(checkedState[app.app_name]?.isChecked?selectedAppRoles[app.app_name]:[]) || []}
                              onChange={(event) => {
                                handleRoleChange(app.app_name, event);
                              }}
                              size="small"
                              IconComponent={RiArrowDownSLine}
                              disabled={!checkedState[app.app_name]?.isChecked}
                              // error={roleErrors[app.app_name]}
                              multiple
                            >
                              {app.roles.map((role, index) => (
                                <MenuItem
                                  key={index}
                                  value={role.id}
                                  name={role.app_name}
                                  disabled={role.role_name === "Admin"} 
                                >
                                  {role.role_name}
                                </MenuItem>
                              ))}
                            </Select>
                            {/* {roleErrors[app.app_name] && (
                              <FormHelperText error className="select-error">
                                Please select a role for this app
                              </FormHelperText>
                            )} */}
                          </FormControl>
                        </Grid>
                      </Grid>
                    </>
                  ))}
                  {formik.touched.system_id && formik.errors.system_id && (
                    <FormHelperText className="error-message">
                      {formik.errors.system_id}
                    </FormHelperText>
                  )}
                </FormGroup>

                <Typography
                  variant="h3"
                  component="h3"
                  sx={{ marginTop: "16px" }}
                >
                  Announcement Display
                </Typography>
                <Grid
                  container
                  gap={1}
                  // rowGap={0.1}
                  // columnGap={0.1}
                  sx={{ marginBottom: "24px" }}
                  alignItems={"center"}
                  // justifyContent={"space-between"}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    rowSpacing={0}
                    sx={{
                      alignSelf: "flex-start",
                      paddingTop: {
                        xs: "0px", // Extra small screens
                        sm: "0px", // Small screens
                        md: "0px", // Medium screens
                        lg: "0px", // Large screens
                        xl: "0px", // Extra large screens
                      },
                    }}
                  >
                    <FormControl>
                      {/* <FormLabel>Tick to display important announcement on Dashboard marquee</FormLabel> */}
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="medium"
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                            
                                formik.setFieldValue("is_priority", isChecked);
                            
                                // Calculate 7 days from now and set display_till in the desired format if checked
                                const sevenDaysLater = new Date();
                                
                                formik.setFieldValue(
                                  "display_till",
                                  isChecked ? new Date(new Date().setDate(new Date().getDate() + 7)) : null
                                );
                               formik.setFieldValue(
                                  "display_till_time",isChecked ? new Date().setHours(0, 0):null)
                              }}
                              color="primary"
                              name="is_priority"
                            />
                          }
                          label={
                            <span style={styles.label}>
                              Highlight Announcement on Dashboard ?
                              {/* <br /> */}
                              {/* <span style={{ fontStyle: 'italic', color: '#888', fontSize: '0.9em' }}>
                           ( If date & time not set, then it will display in marquee forever )
                          </span> */}
                            </span>
                          }
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  {formik.values.is_priority ? (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={5.9}
                        md={5.9}
                        lg={5.9}
                        sx={{ alignSelf: "flex-start" }}
                      >
                        <FormControl fullWidth>
                          <FormLabel>Until Date</FormLabel>
                          <DatePicker
                            oneTap
                            id="app.name"
                            format="dd-MM-yyyy"
                            placement="topStart"
                            name="display_till"
                            value={
                              formik.values.display_till
                                ? formik.values.display_till
                                : null
                            }
                            onChange={(value) => {
                              formik.setFieldValue("display_till", value);
                              if (value == null) {
                                formik.setFieldValue("display_till_time", null);
                              } else {
                                formik.setFieldValue(
                                  "display_till_time",
                                  formik.values.display_till_time
                                    ? formik.values.display_till_time
                                    : new Date().setHours(0, 0)
                                );
                              }
                            }}
                            onBlur={formik.handleBlur}
                            placeholder="Enter Date"
                            disabledDate={(date) =>
                              date && date < new Date().setHours(0, 0, 0, 0)
                            }
                          />
                        </FormControl>

                        {formik.touched.display_till &&
                          formik.errors.display_till && (
                            <FormHelperText className="error-message">
                              {formik.errors.display_till}
                            </FormHelperText>
                          )}
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={5.9}
                        md={5.9}
                        lg={5.9}
                        sx={{ alignSelf: "flex-start" }}
                      >
                        <FormControl fullWidth>
                          <FormLabel>Until Time</FormLabel>
                          <DatePicker
                            showMeridian
                            placement="topStart"
                            name="display_till_time"
                            format="hh:mm aa"
                            placeholder="Enter Time"
                            value={
                              formik.values.display_till_time
                                ? formik.values.display_till_time
                                : formik.values.display_till
                                ? new Date().setHours(0, 0)
                                : null
                            }
                            onChange={(value) => {
                              formik.setFieldValue("display_till_time", value);
                            }}
                            hideMinutes={(minute) => minute % 5 !== 0}
                            onBlur={formik.handleBlur}
                          />
                        </FormControl>
                      </Grid>

                      {/* Message beneath the DatePicker components */}
                      <Grid item xs={12}>
                      <Typography
  component="span"
  sx={{
    fontStyle: 'italic',
    color: '#888',
    fontSize: '0.9em',
    fontWeight:"bold"
  }}
>
  ( If date & time not selected, then it will display on Dashboard forever. )
</Typography>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>

            <Box className="footer-wrapper">
              <Button
                variant="contained"
                size="large"
                className="primary-btn btn"
                disableRipple
                type="submit"
                disabled={formik.isSubmitting}
                sx={{ width: "120px" }}
              >
                Save
              </Button>
              <Button
                variant="contained"
                size="large"
                className="secondary-btn btn"
                disableRipple
                onClick={() =>
                  navigate("/announcements", {
                    state: {
                      status: state.status ? state.status : 0,
                      filterApplied: state.filterApplied
                        ? state.filterApplied
                        : false,
                      filterYear: state.filterYear ? +state.filterYear : "",
                      filterMonth: state.filterMonth ? state.filterMonth : "",
                      filterType: state.filterType ? state.filterType : "",
                      filterPostedBy: state.filterPostedBy
                        ? state.filterPostedBy
                        : "",
                      filterPostedDate:state.filterPostedDate?state.filterPostedDate:'',
                      page:state.page?state.page:1,
                      rowsPerPage:state.rowsPerPage?state.rowsPerPage:25
                    },
                  })
                }
                sx={{ width: "120px" }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default AnnouncementCreate;
